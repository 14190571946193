<template>
	<div class="lang-selector">
		<div v-for="(lang, index) in langs" :key="lang.id" class="button">
			<a
				tabindex="1"
				class="link"
				:class="{
					active: currentLocale === lang.id,
				}"
				v-on:keyup.enter="langButtonHandler(lang.id)"
				@click="langButtonHandler(lang.id)"
			>
				{{ lang.label }}
			</a>
			<p v-if="index !== langs.length - 1" class="divider"></p>
		</div>
	</div>
</template>

<script setup>
import { toRefs } from "vue"
import { UseRootStore } from "@/model/RootStore"

const store = UseRootStore()
const langButtonHandler = lang => {
	store.updateLanguage(lang)
}

const { currentLocale } = toRefs(store)

defineProps({
	langs: {
		type: Array,
		default: () => [
			{ id: "fr", label: "Fr" },
			{ id: "en", label: "En" },
		],
	},
})
</script>

<style scoped>
.lang-selector {
	display: flex;

	.active {
		font-weight: bold;
	}

	.button {
		font-weight: 500;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.link {
			cursor: pointer;
			color: #617179;

			&.active {
				font-weight: bold;
			}
		}
	}

	.divider {
		width: 1px;
		height: 80%;
		margin: auto;
		background-color: #dbdbdb;
		margin-left: 8px;
		margin-right: 8px;
	}
}
</style>
