<template>
	<div class="layout">
		<left-panel-drawer v-if="isLargeScreen" class="panel" :is-open="isLeftPanelOpen">
			<slot name="left-panel">left-panel</slot>
		</left-panel-drawer>

		<slot name="map">map</slot>
	</div>
</template>

<script setup>
import LeftPanelDrawer from "@/components/menu-left/LeftPanelDrawer.vue"
import { inject } from "vue"

const isLargeScreen = inject("isLargeScreen")

const props = defineProps({
	isLeftPanelOpen: Boolean,
})
</script>

<style scoped>
.layout {
	position: relative;
	width: 100%;
	height: 100%;

	.panel {
		position: absolute;
		z-index: 100;
		top: 1.2rem;
		left: 1.2rem;
		overflow-y: auto;
	}
}
</style>
