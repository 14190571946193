import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

import App from '@/App.vue'
import AppRouter from '@/AppRouter'
import i18n from '@/lang/i18n'
import { useMediaQuery } from '@vueuse/core'

import 'normalize.css'
import '@/assets/styles/main.css'

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const app = createApp(App)

// enable the vue devtools even in production
app.config.devtools = true

app.use(createPinia())
app.use(AppRouter)
app.use(i18n)

app.provide('isLargeScreen', isLargeScreen)

const environment = import.meta.env.MODE

// Setup error tracking
Sentry.init({
	app,
	dsn: 'https://123581839d5e4cfd925e916ed90aa68f@o733221.ingest.sentry.io/4505003190910976',

	integrations: [
		Sentry.browserTracingIntegration({ AppRouter }),
		Sentry.replayIntegration(),
		],
	environment: environment,

	tracePropagationTargets: ['localhost', 'app-staging.landerz.ca', /^\//],

	// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Sets the sample rate to be 10% for full sessions
	replaysSessionSampleRate: 1,

	// Sets the sampel rate to be 100% for session with an error
	replaysOnErrorSampleRate: 1.0,
})

app.mount('#app')
