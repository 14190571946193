<template>
	<div class="editable-field" :class="{ 'is-missing': isMissing(value) }">
		<span class="title" :class="{ info }">{{ title }}</span>

		<!-- prettier-ignore -->
		<span class="value">
			<input v-if="type == 'text'"     type="text"     :name="name" :value="value"   @input="onInput" :disabled="disabled" />
			<input v-if="type == 'number'"   type="number"   :name="name" :value="value"   @input="onInput" :disabled="disabled" :step="rounding" />
			<input v-if="type == 'checkbox'" type="checkbox" :name="name" :checked="value" @input="onInput" :disabled="disabled" ref="checkbox" />
			<input v-if="type == 'date'"     type="date"     :name="name" :value="value"   @input="onInput" :disabled="disabled" />
			<input v-if="type == 'file'"     type="file"     :name="name" :value="value"   @input="onInput" :disabled="disabled" />

			<a v-if="type == 'url'" :href="value" target="_blank">{{ name }}</a>

			<select v-if="type == 'select'" name="name" @input="onInput" :disabled="disabled" :multiple="multiple" ref="select">
				<option></option>
				<option v-for="option of options" :name="option" :selected="([].concat(value ?? [])).includes(option)">{{ option }}</option>
			</select>

			<textarea v-if="type == 'textarea'" :name="name" @input="onInput" :disabled="disabled">{{ value }}</textarea>

			<span class="clear" v-if="type == 'checkbox'" @click="clear">✖</span>
		</span>

		<span class="symbol">{{ symbol }}</span>
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import debounce from "lodash.debounce"

const props = defineProps({
	type: {
		type: String,
		default: "text",
	},
	title: {
		type: String,
		default(props) {
			return props["name"]
		},
	},
	name: {
		type: String,
		required: true,
	},
	value: {
		type: [String, Number, Date, Boolean, Array, null],
		required: true,
	},
	options: {
		type: Object,
		default(props) {
			return {}
		},
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	multiple: {
		type: Boolean,
		default: false,
	},
	rounding: {
		type: Number,
		default: 1,
	},
	symbol: {
		type: String,
		default: "",
	},
	info: {
		type: Boolean,
		default: false,
	},
})

const select = ref(null)
const checkbox = ref(null)

onMounted(() => {
	// adjust multi-select size dynamically
	if (select.value && select.value.multiple) {
		select.value.size = select.value.options.length
	}
})

const emit = defineEmits(["input"])

function isMissing(value) {
	return value === null || value === ""
}

function clear(event) {
	checkbox.value.dataset.clear = true
	checkbox.value.checked = false

	checkbox.value.dispatchEvent(new Event("input"))
}

const onInput = debounce(event => {
	const type = props.type
	const name = props.name
	const multiple = props.multiple

	let value = event.target.value

	if (value === "") {
		// default value
		value = null
	} else {
		// cast
		switch (type) {
			case "number": {
				value = Number(value)
				break
			}
			case "checkbox": {
				if (checkbox.value.dataset.clear) {
					delete checkbox.value.dataset.clear
					value = null
				} else {
					value = Boolean(checkbox.value.checked)
				}
				break
			}
			case "select": {
				if (multiple) {
					value = Array.from(event.target.selectedOptions).map(opt => opt.value)
				}
				break
			}
		}
	}

	emit("input", { name, value })
}, 300)
</script>

<style scoped>
.editable-field {
	display: flex;
	font-size: 1.3em;
	gap: 5px;

	.title {
		flex: 0 0 150px;
		align-self: center;

		&.info {
			color: hsl(0 0% 40%);

			&::before {
				content: "⤷ ";
				font-weight: bold;
				font-size: 130%;
			}
		}
	}

	&.is-missing {
		.title {
			color: var(--color-transaction);
		}

		.clear {
			display: none;
		}
	}

	.value {
		flex: 1;
		align-self: flex-end;

		display: flex;
		justify-content: flex-start;

		overflow: hidden;

		:is(input:not([type="checkbox"]), select, textarea) {
			width: 100%;
			padding: 0.5em;
		}

		textarea {
			min-height: 100px;
			line-height: 1.3em;
		}

		.clear {
			margin-left: 20px;
			color: hsl(0 40 50);
			opacity: 0.6;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}
		}
	}

	.symbol:not(:empty) {
		flex-basis: 10px;
		display: flex;
		align-items: center;
		font-size: 140%;
	}
}
</style>
