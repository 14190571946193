<template>
	<div class="tabs">
		<div
			v-for="item in items"
			:key="item.id"
			class="toggle"
			:class="{ active: selectedRef === item.id }"
			:id="'toggle_' + item.id"
		>
			<label class="label" :class="{ active: selectedRef === item.id }" :for="item.id">
				{{ $t(`listings.tabs.${item.label || item.id}`) }}
				<div class="switch">
					<input :id="item.id" v-model="selectedRef" type="radio" :value="item.id" />
					<span class="slider"></span>
				</div>
			</label>
			<slot :name="item.id" :item="item" />
		</div>
	</div>
</template>

<script setup>
import { ref, watch } from "vue"
const props = defineProps({
	modelValue: {
		type: String,
		default: () => "one",
	},
	items: {
		type: Array,
		default: () => [
			{ id: "one", label: "Un" },
			{ id: "two", label: "Deux" },
			{ id: "three", label: "Trois" },
		],
	},
})
const emit = defineEmits(["update:modelValue"])
const selectedRef = ref(props.modelValue)
watch(selectedRef, newVal => {
	emit("update:modelValue", newVal)
})
</script>

<style scoped>
.tabs {
	display: flex;
}
.toggle {
	padding: 0;

	.label {
		display: block;
		width: 100%;
		padding: 0;
		padding-bottom: 6px;
		color: #c4c4c4;
		font-family: "basier_circlemedium", sans-serif;
		font-size: 12px;
		text-align: center;
		border-bottom: 1.5px solid;
		border-color: #c4c4c4;
		cursor: pointer;
		transition:
			color 0.2s ease-out,
			border-color 0.2s ease-out;

		&.active {
			color: #1dbf73;
			border-color: #1dbf73;
			cursor: default;
		}
	}
	&:hover > label {
		color: #777777;
		border-color: #777777;

		.active {
			color: #1dbf73;
			border-color: #1dbf73;
		}
	}
	.switch {
		display: none;
	}
}
</style>
