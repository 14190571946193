<template>
	<div class="transaction">
		<div class="header">
			<input
				type="checkbox"
				@change.stop="event => handleAddToExportList(event, transaction)"
				@click.stop
				:checked="store.transactionExportList.has(transaction.presentationId)"
			/>
			<div class="title">
				<h3 class="address" :class="transaction.location.address ?? 'unavailable'">
					{{ transaction.location.address || $t("unknownAddress") }}
				</h3>
				<div class="borough">
					{{ transaction.location?.borough }}
				</div>
			</div>
		</div>
		<div class="separator"></div>

		<div class="items">
			<header>
				<h1 class="price">
					<span>
						<span class="currency">$</span>
						<span class="amount">{{ (transaction.deal?.price || "").toLocaleString() }}</span>
					</span>
				</h1>

				<div class="legacy-hint">
					{{ transaction.hasLegacy ? "legacy" : "" }}
				</div>

				<label v-if="isWriter" class="status">
					<select name="metadata_status" @change="onStatusChange">
						<option
							v-for="(status, idx) of settableTransactionStatuses"
							:value="status"
							:key="idx"
							:selected="status == transaction.metadata.status"
						>
							{{ status }}
						</option>
					</select>
				</label>
			</header>

			<!-- prettier-ignore -->
			<div class="details">

				<fieldset>
					<legend>info</legend>

					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "inscriptionNumber"
						:value    = "spaceOutNumbers(transaction.inscriptionNumber)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "deedOfSale"
						:name     = "getUrlTrailingSegments(transaction.deedOfSaleUrl, 2) || 'N/A'"
						:value    = "transaction.deedOfSaleUrl"
					/>
					<TransactionCardDetailedEditableField
						type      = "date"
						name      = "deedOfSaleDate"
						:value    = "transaction.deedOfSaleDate.toISOString().split('T')[0]"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "date"
						name      = "psaDate"
						:value    = "transaction.psaDate && transaction.psaDate.toISOString().split('T')[0]"
						@input    = "onFieldInput"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "type"
						:options  = "TransactionType"
						:value    = "transaction.type || TransactionTypeDefault"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						v-if      = "isWriter"
						info
						type      = "text"
						title     = "cmsd category"
						name      = "cmsdCategory"
						:value    = "transaction.category"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						name      = "parcels"
						:value    = "spaceOutNumbers(...transaction.location?.parcelNumbers).join(', ')"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "parcelImage"
						:name     = "getUrlTrailingSegments(transaction.interactiveMapUrl, 2) || 'N/A'"
						:value    = "transaction.interactiveMapUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "3dImage"
						:name     = "getUrlTrailingSegments(transaction.sateliteMapUrl, 2) || 'N/A'"
						:value    = "transaction.sateliteMapUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						title     = "totalLandArea"
						name      = "superficy"
						:value    = "transaction.superficy?.squareFeet ?? null"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:symbol   = "'$'"
						name      = "salePrice"
						:value    = "transaction.salePrice"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "assessmentRole"
						:name     = "getUrlTrailingSegments(transaction.cityInformationSheetUrl, 2) || 'N/A'"
						:value    = "transaction.cityInformationSheetUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "dataRoomLink"
						:value    = "transaction.dataRoomLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
         	<TransactionCardDetailedEditableField
						type      = "textarea"
						name      = "comments"
						:value    = "transaction.comments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>location</legend>

					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "address"
						:value    = "transaction.location?.address"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "municipality"
						name      = "city"
						:value    = "transaction.location?.municipality"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "postalCode"
						:value    = "spaceOutNumbers(transaction.postalCode).join(', ')"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>parties</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "saleContext"
						:options  = "TransactionSaleContext"
						:value    = "transaction.saleContext"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "buyerName"
						name      = "buyer"
						:value    = "transaction.buyer?.name"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "buyerRepresentatives"
						:value    = "transaction.buyer?.representatives"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "buyerType"
						:options  = "TransactionPartyType"
						:value    = "transaction.buyer?.type"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "buyerNeq"
						:value    = "transaction.buyer?.neq"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "buyerActivity"
						:value    = "transaction.buyer?.activity"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						title     = "sellerName"
						name      = "seller"
						:value    = "transaction.seller?.name"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "sellerRepresentatives"
						:value    = "transaction.seller?.representatives"
						@input    = "onFieldInput"
						disabled
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "sellerType"
						:options  = "TransactionPartyType"
						:value    = "transaction.seller?.type"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "sellerNeq"
						:value    = "transaction.seller?.neq"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "sellerActivity"
						:value    = "transaction.seller?.activity"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "broker"
						:value    = "transaction.broker"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "agency"
						:value    = "transaction.agency"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "marketplace"
						:value    = "transaction.marketplace"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "marketplaceId"
						:value    = "transaction.marketplaceId"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>lender</legend>

					<TransactionCardDetailedEditableField
						type      = "checkbox"
						name      = "mortgage"
						:value    = "transaction.mortgage"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "lender"
						:value    = "transaction.lender"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:symbol   = "'$'"
						name      = "lenderAmount"
						:value    = "transaction.lenderAmount"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>pricing</legend>

					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						:symbol   = "'$'"
						name      = "pricePerLandSqft"
						:value    = "roundTo(transaction.pricePerLandSqft, 0.01)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						:symbol   = "'$'"
						name      = "pricePerDevelopableSqft"
						:value    = "roundTo(transaction.pricePerDevelopableSqft, 0.01)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "0.01"
						:symbol   = "'$'"
						name      = "pricePerBuildableSqft"
						:value    = "roundTo(transaction.pricePerBuildableSqft, 0.01)"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:rounding = "100"
						:symbol   = "'$'"
						name      = "pricePerUnit"
						:value    = "roundTo(transaction.pricePerUnit, 100)"
						@input    = "onFieldInput"
						disabled
					/>
				</fieldset>

				<fieldset>
					<legend>type</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "landSubtype"
						:options  = "TransactionLandSubtype"
						:value    = "transaction.landSubtype"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						name      = "authorizedUses"
						:options  = "TransactionAuthorizedUse"
						:value    = "transaction.authorizedUses"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "currentState"
						:options  = "TransactionCurrentState"
						:value    = "transaction.currentState"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						name      = "tod"
						:value    = "transaction.tod"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>zoning</legend>

					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "zoningPlanLink"
						:value    = "transaction.zoningPlanLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "zoningPlanImage"
						:name     = "getUrlTrailingSegments(transaction.zoningImageUrl, 2) || 'N/A'"
						:value    = "transaction.zoningImageUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "zoningGridFile"
						:name     = "getUrlTrailingSegments(transaction.zoningReportUrl, 2) || 'N/A'"
						:value    = "transaction.zoningReportUrl"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "zoningGridLink"
						:value    = "transaction.zoningGridLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "urbanPlanLink"
						:value    = "transaction.urbanPlanLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "padLink"
						:value    = "transaction.padLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "otherZoningDocumentLink"
						:value    = "transaction.otherZoningDocumentLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

				<fieldset>
					<legend>development</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "codificationType"
						:options  = "TransactionCodificationType"
						:value    = "transaction.codificationType"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "projectApproval"
						:options  = "TransactionProjectApproval"
						:value    = "transaction.projectApproval"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "projectApprovalAtDeedOfSale"
						:options  = "TransactionProjectApprovalAtDeedOfSale"
						:value    = "transaction.projectApprovalAtDeedOfSale"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "projectDocument"
						:value    = "transaction.projectDocument"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "projectLink"
						:value    = "transaction.projectLink"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "url"
						title     = "cmsd3dModel"
						:name     = "getUrlTrailingSegments(transaction.volumetricImageUrl, 2) || 'N/A'"
						:value    = "transaction.volumetricImageUrl"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						name      = "projectTypes"
						:options  = "TransactionProjectType"
						:value    = "transaction.projectTypes"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "stories"
						:value    = "transaction.stories"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:symbol   = "'%'"
						name      = "siteCoverageRatio"
						:value    = "transaction.siteCoverageRatio"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "density"
						:value    = "transaction.density"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "buildableAreaSqft"
						:value    = "roundTo(transaction.buildableAreaSqft, 0.1)"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "numberOfUnits"
						:value    = "transaction.numberOfUnits"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "parkingOutdoorCount"
						:value    = "transaction.parkingOutdoorCount"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "parkingIndoorCount"
						:value    = "transaction.parkingIndoorCount"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "parkingTotal"
						:value    = "transaction.parkingTotal"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "parkingRatio"
						:value    = "transaction.parkingRatio"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						name      = "projectComments"
						:value    = "transaction.projectComments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "unitType"
						:options  = "TransactionUnitType"
						:value    = "transaction.unitType"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "unitAreaSqft"
						:value    = "transaction.unitAreaSqft"
						@input    = "onFieldInput"
						disabled
					/>
				</fieldset>

				<fieldset>
					<legend>restrictions</legend>

					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						name      = "cptaq"
						:options  = "TransactionCPTAQ"
						:value    = "transaction.cptaq"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						name      = "urbanPerimeter"
						:options  = "TransactionUrbanPerimeter"
						:value    = "transaction.urbanPerimeter"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						multiple
						name      = "infrastructure"
						:options  = "TransactionInfrastructure"
						:value    = "transaction.infrastructure"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "landShape"
						:options  = "TransactionLandShape"
						:value    = "transaction.landShape"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "text"
						name      = "parcelReport"
						:value    = "transaction.parcelReport"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "contamination"
						:options  = "TransactionContamination"
						:value    = "transaction.contamination"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:symbol   = "'$'"
						name      = "contaminationCost"
						:value    = "transaction.contaminationCost"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						name      = "wetlands"
						:value    = "transaction.wetlands"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						name      = "floodableZone"
						:value    = "transaction.floodableZone"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "checkbox"
						name      = "endangeredSpecies"
						:value    = "transaction.endangeredSpecies"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:symbol   = "'%'"
						name      = "deductionRatio"
						:value    = "transaction.deductionRatio"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						name      = "deductionComments"
						:value    = "transaction.deductionComments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "select"
						name      = "deductionFiles"
						:value    = "transaction.deductionFiles"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "developableLandAreaSqft"
						:value    = "transaction.developableLandAreaSqft"
						@input    = "onFieldInput"
						disabled
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						:symbol   = "'%'"
						name      = "infrastructureDeductionRatio"
						:value    = "transaction.infrastructureDeductionRatio"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "textarea"
						name      = "infrastructureDeductionComments"
						:value    = "transaction.infrastructureDeductionComments"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
					<TransactionCardDetailedEditableField
						type      = "number"
						name      = "netLandAreaSqft"
						:value    = "transaction.netLandAreaSqft"
						@input    = "onFieldInput"
						:disabled = "!isWriter"
					/>
				</fieldset>

			</div>
		</div>
	</div>
</template>

<script setup>
import { toRaw, computed } from "vue"
import { UseRootStore, Permission } from "@/model/RootStore"
import {
	TransactionType,
	TransactionTypeDefault,
	TransactionSaleContext,
	TransactionPartyType,
	TransactionLandSubtype,
	TransactionAuthorizedUse,
	TransactionCurrentState,
	TransactionCodificationType,
	TransactionProjectApproval,
	TransactionProjectApprovalAtDeedOfSale,
	TransactionProjectType,
	TransactionUnitType,
	TransactionCPTAQ,
	TransactionUrbanPerimeter,
	TransactionInfrastructure,
	TransactionLandShape,
	TransactionContamination,
	TransactionMetadataStatus,
} from "@/model/DataModel"
import { updateTransaction } from "@/utils/ApiClient"

import TransactionCardDetailedEditableField from "@/components/menu-right/TransactionCardDetailedEditableField.vue"

const props = defineProps({
	transaction: {
		type: Object,
		require: true,
	},
})

console.debug(toRaw(props.transaction))

const store = UseRootStore()

const isWriter = store.currentUser?.has(Permission.TransactionWrite)

const settableTransactionStatuses = Object.freeze([TransactionMetadataStatus.Deferred, TransactionMetadataStatus.Done])

function getUrlTrailingSegments(url, count = 1) {
	if (!url) {
		return null
	}

	const u = new URL(url)

	// Split the pathname into segments
	const segments = u.pathname.split("/").filter(Boolean)

	// Extract the last `count` segments
	return segments.slice(-count).join("/")
}

function spaceOutNumbers(...nums) {
	return nums
		.filter(Boolean)
		.map(String)
		.map(n => n.replace(/\B(?=(\d{3})+(?!\d))/g, " "))
}

function roundTo(value, multiple) {
	if (!value) return null
	return Math.round(value / multiple) * multiple
}

function onFieldInput({ name, value }) {
	if (!isWriter) {
		return
	}

	const { inscriptionNumber, presentationId } = props.transaction

	updateTransaction(store.userApiToken, inscriptionNumber, presentationId, {
		[name]: value,
	})
}

function onStatusChange(event) {
	const { inscriptionNumber, presentationId } = props.transaction
	const status = event.target.value

	updateTransaction(store.userApiToken, inscriptionNumber, presentationId, {
		metadataStatus: status,
	})
}

function handleAddToExportList(event, transaction) {
	const box = event.target
	const tr = toRaw(transaction)

	// prettier-ignore
	box.checked ?
		store.transactionExportList.set(tr.presentationId, tr) :
		store.transactionExportList.delete(tr.presentationId)
}
</script>

<style scoped>
.transaction {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1.5rem;
	padding: 1.4rem 1.4rem 1.8rem 1.4rem;
	overflow: auto;
	background-color: white;

	& > .header {
		display: flex;
		flex-direction: row;
		gap: 0.4rem;
		min-height: 2.5em;

		input[type="checkbox"] {
			transform: scale(2);
			width: 10px;
			margin-left: 5px;
		}
		.title {
			margin: 0 40px 0 10px;
		}
	}
	.address {
		color: #000;
		font-size: 2.3rem;
		font-weight: 500;
		line-height: 2.53rem;

		&.unavailable {
			color: hsl(0 0 80);
			text-transform: capitalize;
		}
	}
	.borough {
		color: #777;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 1.8rem;
	}
	.separator {
		width: 100%;
		height: 0.15rem;
		background-color: #c4c4c4;
	}
	.items {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 2.4rem;

		header {
			display: flex;
			justify-content: space-around;
			align-items: center;

			.price {
				font-family: "basier_circlesemibold", sans-serif;
				font-size: 3.3rem;
				font-weight: 600;
				line-height: 3.63rem;

				.currency {
					color: black;
					opacity: 0.7;
				}
				.amount {
					color: var(--accent-color);
				}
			}
			.legacy-hint {
				color: gray;
				font-weight: bold;
			}
			.status {
				margin-bottom: -5px;

				select {
					transform: scale(1.3);
					padding: 3px;
				}
			}
		}
		.details {
			display: flex;
			flex-direction: column;
			gap: 3rem;

			fieldset {
				min-width: 0;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 1rem;

				border: 1px solid hsl(0deg 0% 80%);
				padding: 20px 10px 10px 10px;

				legend {
					font-size: 1.9em;
					text-transform: capitalize;
					color: hsl(0 0 40);
					font-weight: bold;
				}
			}
		}
	}
}
</style>
