<template>
	<div v-for="item in items" :key="item.id">
		<label class="toggle" :for="item.id">
			<div v-if="isIconActive">
				<img v-if="item.id === 'landerz' && item.launch_date" src="/assets-flags/flag-landerz-new.svg" alt="" />
				<img v-if="item.id === 'landerz'" src="/assets-flags/flag-landerz.svg" alt="" />
				<img v-if="item.id === 'off_market' && item.launch_date" src="/assets-flags/flag-off_market-new.svg" alt="" />
				<img v-if="item.id === 'off_market'" src="/assets-flags/flag-off_market.svg" alt="" />
				<img v-if="item.id === 'fsbo'" src="/assets-flags/flag-fsbo.svg" alt="" />
				<img v-if="item.id === 'external'" src="/assets-flags/flag-external.svg" alt="" />
				<img v-if="item.id === 'co_listing'" src="/assets-flags/flag-co_listing.svg" alt="" />
				<img v-if="item.id === 'transactions'" src="/icons/transaction.png" alt="" width="26px" />
			</div>
			<div class="right">
				<div
					class="label"
					:class="{
						closed: !selectedRef.includes(item.id),
					}"
				>
					{{ item.label }}
				</div>
				<div class="switch">
					<input :id="item.id" v-model="selectedRef" type="checkbox" :value="item.id" />
					<span class="slider"></span>
				</div>
			</div>
		</label>
		<slot :name="item.id" :item="item" />
	</div>
</template>

<script setup>
import { ref, watch } from "vue"
const props = defineProps({
	inputType: {
		type: String,
		default: "checkbox",
	},
	modelValue: {
		type: Array,
		default: () => ["one"],
	},
	isIconActive: {
		type: Boolean,
		default: false,
	},
	items: {
		type: Array,
		required: true,
	},
})
const emit = defineEmits(["update:modelValue"])
const selectedRef = ref(props.modelValue)
watch(selectedRef, newVal => {
	emit("update:modelValue", newVal)
})
</script>

<style>
.toggle {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	gap: 1.2rem;
	width: 100%;
	cursor: pointer;

	.right {
		flex-grow: 1;
		display: flex;
		justify-content: space-between;
	}
	.label {
		color: #191716;
		font-family: "basier_circleregular", sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;

		&.closed {
			color: #777777;
		}
	}
	.switch {
		position: relative;
		display: inline-block;
		width: 38px;
		height: 20px;

		input {
			opacity: 0;
			width: 0;
			height: 0;

			&:checked + .slider {
				background-color: #1dbf73;
			}
			&:focus + .slider {
				box-shadow: 0 0 1px var(--primary-color);
			}
			&:checked + .slider:before {
				left: 20px;
			}
		}
		.slider {
			position: absolute;
			cursor: pointer;
			border-radius: 34px;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #777777;
			-webkit-transition: 0.2s;
			transition: 0.2s;

			&:before {
				position: absolute;
				content: "";
				height: 16px;
				width: 16px;
				left: 2px;
				bottom: 2px;
				background-color: #fff;
				-webkit-transition: 0.4s;
				transition: 0.4s;
				border-radius: 50%;
			}
		}
	}
}
</style>
