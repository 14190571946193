<template>
	<main>
		<div class="list-panel">
			<!-- listing details -->
			<right-panel v-model:isOpen="isShowingListingDetailsPanel" :width="'46.1rem'" :height="'80%'" :maxHeight="'94vh'">
				<!-- The selected parcel base info  -->
				<listing-card-detailed :item="listings.find(l => l.id === selectedListingId)" />
			</right-panel>

			<!-- parcel details -->
			<right-panel v-model:isOpen="isShowingParcelDetailsPanel" :width="'46.1rem'" :height="'80%'" :maxHeight="'94vh'">
				<!-- The selected listing details -->
				<parcel-card-detailed v-if="selectedParcel" :item="selectedParcel" />
			</right-panel>

			<!-- transaction details -->
			<right-panel
				v-if="isUserLoggedIn && config.ui.has(Feature.Transactions)"
				v-model:isOpen="isShowingTransactionDetailsPanel"
				:width="'46.1rem'"
				:height="'80%'"
				:maxHeight="'94vh'"
			>
				<!-- The selected transaction's details -->
				<TransactionCardDetailed
					v-if="selectedTransaction"
					:transaction="selectedTransaction"
					:key="selectedTransaction.id"
				/>
			</right-panel>

			<!-- comparables -->
			<right-panel
				class="comparables"
				:dismissible="false"
				:isOpen="isUserLoggedIn && transactionExportList.size > 0"
				:width="'54rem'"
				:maxHeight="'94vh'"
			>
				<div class="content">
					<div class="header">
						<h4>{{ $t("comparablesHeader") }}</h4>
					</div>
					<div class="separator"></div>
					<div class="export">
						<div class="count">({{ transactionExportList.size }}) {{ $t("selected") }}</div>
						<button @click="exportTransactions(Array.from(toRaw(transactionExportList).values()))">
							{{ $t("export") }}
						</button>
					</div>
					<div class="items">
						<TransactionCard
							class="item"
							v-if="isUserLoggedIn && config.ui.has(Feature.Transactions)"
							v-for="[_, transaction] in transactionExportList"
							:key="transaction.presentationId"
							:transaction="transaction"
							:selected="selectedTransaction && transaction.presentationId === selectedTransaction.presentationId"
							@mouseenter="handlePreviewedTransaction(transaction)"
							@mouseleave="handlePreviewedTransaction(null)"
							@click="handleSelectedTransaction(transaction)"
						>
						</TransactionCard>
						<!-- :previewed="previewedTransaction && transaction.id === previewedTransaction.id" -->
					</div>
				</div>
			</right-panel>
		</div>
	</main>
</template>

<script setup>
import ListingCardDetailed from "@/components/menu-right/ListingCardDetailed.vue"
import ParcelCardDetailed from "@/components/menu-right/ParcelCardDetailed.vue"
import TransactionCardDetailed from "@/components/menu-right/TransactionCardDetailed.vue"
import RightPanel from "@/components/menu-right/RightPanel.vue"
import TransactionCard from "@/components/menu-right/TransactionCard.vue"
import * as download from "@/utils/download"
import { UseRootStore } from "@/model/RootStore"
import { config, Feature } from "@/AppConfig.ts"
import { storeToRefs } from "pinia"
import { computed, ref, watch, toRaw } from "vue"
import Papa from "papaparse"
import { flatten } from "flat"

const store = UseRootStore()
const {
	listings,
	hoveredListingId,
	selectedListingId,
	selectedParcelID,
	selectedParcel,
	selectedTransaction,
	previewedTransaction,
	transactionExportList,
	isUserLoggedIn,
} = storeToRefs(store)

const isShowingListingDetailsPanel = ref(false)
const isShowingParcelDetailsPanel = ref(false)
const isShowingTransactionDetailsPanel = ref(false)

function handlePreviewedTransaction(transaction) {
	previewedTransaction.value = transaction || null
}

function handleSelectedTransaction(transaction) {
	store.updateSelectedTransaction(transaction)
}

function exportTransactions(transactions) {
	try {
		const data = Array.from(transactions).map(tr => {
			tr = flatten(tr, { safe: true })
			;(tr["documentNumber"] = tr["id"]), delete tr["id"] // use documentNumber instead of 'id'
			tr["parcels"] = tr["parcels"].filter(Boolean).map(p => p.parcelID) // unwrap parcels into their ids
			return tr
		})
		const csv = Papa.unparse(data)
		const blob = new Blob([csv], { type: "text/csv" })

		download.push(blob, "transactions.csv")
	} catch (error) {
		window.alert(`CSV download failed\n\n${error}`)
	}
}

watch(selectedListingId, newState => {
	const isListingSelected = Boolean(newState)

	isShowingListingDetailsPanel.value = isListingSelected
	if (isListingSelected) {
		isShowingTransactionDetailsPanel.value = false
	}
})
watch(selectedParcelID, newState => {
	const isParcelSelected = Boolean(newState)

	isShowingParcelDetailsPanel.value = isParcelSelected
	if (isParcelSelected) {
		isShowingTransactionDetailsPanel.value = false
	}
	if (selectedListingId.value) {
		isShowingListingDetailsPanel.value = !isParcelSelected
	}
})
watch(selectedTransaction, newState => {
	const isTransactionSelected = Boolean(newState)

	// prettier-ignore
	if (isTransactionSelected) {
		isShowingTransactionDetailsPanel.value = true
		isShowingListingDetailsPanel.value     = false
		isShowingParcelDetailsPanel.value      = false
	} else {
		isShowingTransactionDetailsPanel.value = false
	}
})
</script>

<style scoped>
.list-panel {
	position: absolute;
	top: 4.2rem;
	right: 1.2rem;
	display: flex;
	gap: 0.6rem;
	max-height: calc(100% - 6rem);
	pointer-events: none;
	transition:
		right ease-out 0.2s,
		gap ease-out 0.2s;

	.right-panel {
		pointer-events: auto;
		overflow: auto;

		&.comparables {
			.content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				min-width: 40rem;
				height: 100%;
				gap: 2.05rem;
				overflow-y: auto;
				overflow-x: hidden;
				padding: 1.8rem 1.4rem 2.4rem 1.4rem;
			}
			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 0.6rem;
				h4 {
					font-size: 1.8rem;
					font-family: "basier_circlemedium", sans-serif;
					font-weight: 500;
					color: #191716;
					text-transform: capitalize;
				}
			}
			.separator {
				width: 100%;
				height: 0.15rem;
				background-color: #c4c4c4;
			}
			.export {
				display: flex;
				gap: 20px;
				font-size: 1.5em;
				align-items: baseline;

				button {
					order: -1;
					padding: 0.5em 2em;
					background-color: black;
					color: white;
					font-weight: bold;
					border-radius: 5px;
					cursor: pointer;
					box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.24);

					&:active {
						box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.24);
						transform: translateX(1px) translateY(1px);
					}
				}
			}
			.items {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				gap: 10px;
				overflow-y: auto;
				overflow-x: hidden;

				.item {
					margin-right: 10px;
				}
			}
		}
	}
}
</style>
