import i18n, { updatePageMetaInfo } from "@/lang/i18n"
import { DevelopmentTypes, LayerTypes, Listing, Transaction, LayerType } from "@/model/DataModel.ts"
import { defineStore } from "pinia"
import { reactive } from "vue"
import { useLocalStorage } from "@vueuse/core"
import { config, Feature } from "@/AppConfig.ts"
import { getTransactionByPresentationId } from "@/utils/ApiClient"
import { jwtDecode, JwtPayload } from "jwt-decode"

export interface MapboxCameraPosition {
	zoom: number
	centerLatitude: number
	centerLongitude: number
	bearing?: number
	pitch?: number
}

export enum Permission {
	TransactionRead = "transaction.read",
	TransactionWrite = "transaction.write",
}

class User {
	private readonly role: string
	private readonly permissions: string[]

	constructor(role: string, permissions: string[]) {
		this.role = role
		this.permissions = permissions
	}

	static fromToken(token: string): User | null {
		const { role, permissions } = jwtDecode<JwtPayload>(token)
		return new User(role, permissions)
	}

	has(p: string): boolean {
		return this.permissions.includes(p)
	}
}

type MapboxCameraPositionHash =
	| `${number}/${number}/${number}`
	| `${number}/${number}/${number}/${number}`
	| `${number}/${number}/${number}/${number}/${number}`

function isValidDate(date: any): boolean {
	return date instanceof Date && !isNaN(date.getTime())
}

export const UseRootStore = defineStore("root-store", {
	state: () => ({
		cameraPosition: useLocalStorage<MapboxCameraPosition>("cameraPosition", {} as MapboxCameraPosition),

		userApiToken: useLocalStorage("userApiToken", "") as string,

		filters: {
			// By default, show the listing of all types [landerz, off_market, ...]
			layers: LayerTypes,

			// By default, show the listings of all development types [Résidentiel, Commercial, ...]
			devTypes: DevelopmentTypes,

			// prettier-ignore
			rangeFilters: {
				superficy:     { min: 0, max: 5_000_000_000, step: 1_000 },
				constructible: { min: 0, max: 5_000_000_000, step: 1_000 },
				price:         { min: 0, max: 5_000_000_000, step: 1_000 },
				date: {
					min: new Date("2010-01-01T00:00:00"),
					max: new Date("2030-01-01T00:00:00"),
				},
			},

			municipalities: [],
			boroughs: [],

			transactionTypes: [],
			transactionStatuses: [],
			transactionCategories: [],
		},

		// All transactions
		transactions: [] as Transaction[],

		// The currently selected transaction
		selectedTransaction: null as null | Transaction,

		// The currently previewed transaction (e.g. hovered)
		previewedTransaction: null as null | Transaction,

		// The list of transactions that have been selected for export
		transactionExportList: new Map(),

		/// all the listings that we received from the Landerz API
		listings: [] as Listing[],

		/// the listings that are currently visible in the map viewport
		listingsInMapViewPort: [] as Listing[],

		/// the id of the listing that is currently hovered in the right menu
		hoveredListingId: null as null | number,

		/// the id of the listing that is currently selected in the right menu
		selectedListingId: null as null | number,

		// the id of the parcel that is currently selected (by clicking on the cadastre)
		selectedParcelID: null as null | number,

		// the object reprsenting the selected parcel (by clicking on the cadastre)
		selectedParcel: null as null | object,

		// Private state for currently displayed layers.
		// It should be read from using visibleLayers and written to
		// using setVisibleLayers.
		_visibleLayers: null,

		layers: [],

		currentLocale: i18n.global.locale,
	}),
	getters: {
		// Private state for currently displayed layers.
		// It should be read from using visibleLayers and written to
		// using setVisibleLayers.
		defaultVisibleLayers: state => {
			return state.currentUser?.has(Permission.TransactionWrite)
				? ["listings", "cadastre"]
				: ["listings", "cadastre", "prospects"]
		},

		getTransactionById: state => {
			return (id: number): Transaction | null => state.transactions.find(t => t.id === id) || null
		},

		getHydratedTransactionByPresentationId: state => {
			return (id: number): Transactions | null => {
				return getTransactionByPresentationId(state.userApiToken, id)
			}
		},

		// Actual displayed layers.
		// Only returns restricted layers if the user is logged in.
		visibleLayers: state => {
			if (state["_visibleLayers"] == null) {
				state["_visibleLayers"] = state.defaultVisibleLayers
			}

			return state["_visibleLayers"].filter(layer => state.isUserLoggedIn || !config.restrictedLayers.includes(layer))
		},

		listingsSorted: state => [...state.listings].sort((a, b) => (a.id >= b.id ? 1 : -1)),

		isUserLoggedIn: state => {
			return Boolean(state.userApiToken)
		},

		currentUser: (state): User | null => {
			return state.isUserLoggedIn ? User.fromToken(state.userApiToken) : null
		},
	},
	actions: {
		updateSelectedTransaction(transaction) {
			if (config.ui.has(Feature.TransactionSourceAPI)) {
				this.selectedTransaction?.id === transaction?.id
					? (this.selectedTransaction = null)
					: (this.selectedTransaction = this.getTransactionById(transaction?.id))
			} else if (config.ui.has(Feature.TransactionSourceTiles)) {
				this.selectedTransaction?.presentationId === transaction?.presentationId
					? (this.selectedTransaction = null)
					: (this.selectedTransaction = transaction)
			}
		},

		// Replace list of currently displayed layers.
		setVisibleLayers(layers) {
			this["_visibleLayers"] = layers
		},

		updateLanguage(lang) {
			// update all internationalized text strings within app (using t() in
			// templates)
			i18n.global.locale.value = lang

			// update meta information in <head> since some of it is also
			// internationalized
			updatePageMetaInfo(i18n.global.t("meta-title"), i18n.global.t("meta-description"))

			// keep user's preference across sessions
			window.localStorage.setItem("lang", lang)

			// remove lang params if it exists as it's currently only used for SEO
			const url = new URL(window.location)
			if (url.searchParams.has("lang")) {
				// url.searchParams.set('lang', lang)
				url.searchParams.delete("lang")
				window.history.replaceState(null, "", url.toString())
			}
		},

		// Sets the Mapbox [camera
		// position](https://docs.mapbox.com/help/glossary/camera/), as
		// specified in a URL hash (see
		// [option.hash](https://docs.mapbox.com/mapbox-gl-js/api/map))
		//
		// Example:
		//
		//      http://localhost:8080/#12.26/45.49455/-73.60573/-144.6/54
		//
		//      12.26/45.49455/-73.60573/-144.6/54
		//        A      B         C        D   E
		//
		//      A: zoom
		//      B: center latitude
		//      C: center longitude
		//      D: bearing (optional)
		//      E: pitch (optional)
		//
		setCameraPosition(position: MapboxCameraPositionHash): void {
			const [zoom, centerLatitude, centerLongitude, bearing, pitch] = position.split("/")

			this.cameraPosition = {
				zoom,
				centerLatitude,
				centerLongitude,
				bearing,
				pitch,
			} as MapboxCameraPosition
		},

		getCameraPositionHash(): MapboxCameraPositionHash {
			return [
				this.cameraPosition.zoom,
				this.cameraPosition.centerLatitude,
				this.cameraPosition.centerLongitude,
				this.cameraPosition.bearing,
				this.cameraPosition.pitch,
			]
				.filter(Boolean)
				.join("/")
		},

		setLayers(layers) {
			this.layers = layers
		},
	},
})
