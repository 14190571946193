<template>
	<div class="base-layers">
		<img
			src="@/assets/images/map_thumb_simple.png"
			:class="{ selected: currentMapStyle === MAP_STYLES.SIMPLE }"
			@click="$emit('current-map-style', MAP_STYLES.SIMPLE)"
		/>
		<img
			src="@/assets/images/map_thumb_standard.png"
			:class="{ selected: currentMapStyle === MAP_STYLES.STANDARD }"
			@click="$emit('current-map-style', MAP_STYLES.STANDARD)"
		/>
		<img
			src="@/assets/images/map_thumb_satellite.png"
			:class="{ selected: currentMapStyle === MAP_STYLES.SATELLITE }"
			@click="$emit('current-map-style', MAP_STYLES.SATELLITE)"
		/>
	</div>

	<div class="listings-layer-selector">
		<toggle-list v-model="_visibleLayers" :items="selectionItems" />
	</div>

	<aside>* {{ $t(`listings.asterix-zoom`) }}</aside>
</template>

<script setup>
import { config } from "@/AppConfig.ts"
import ToggleList from "@/components/menu-left/filtering/ToggleList.vue"
import { UseRootStore } from "@/model/RootStore"
import { storeToRefs } from "pinia"
import { computed, toRef, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { MAP_STYLES } from "@/components/constants.js"

const props = defineProps({
	currentMapStyle: {
		type: String,
		required: true,
	},
})
const currentMapStyle = toRef(props, "currentMapStyle")

const emit = defineEmits(["current-map-style"])

const { t } = useI18n()

const availableLayers = config.layers
const availableRestrictedLayers = config.restrictedLayers

const store = UseRootStore()
const { visibleLayers } = storeToRefs(store)
const _visibleLayers = ref(visibleLayers.value)
watch(_visibleLayers, layers => {
	store.setVisibleLayers(layers)
})

const selectionItems = computed(() => {
	return store.layers
		.filter(id => availableLayers.includes(id) || (store.isUserLoggedIn && availableRestrictedLayers.includes(id)))
		.map(id => ({
			id,
			label: t(`layers.${id}`),
		}))
})
</script>

<style scoped>
.base-layers {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 22px 0;

	img {
		height: 50px;
		width: 30%;
		cursor: pointer;
		outline: 2px solid transparent;
		outline-offset: -2px;

		&:is(:hover, .selected) {
			outline-color: var(--accent-color);
		}
	}
}

.listings-layer-selector {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;
}

aside {
	margin-top: 22px;
}
</style>
